import {SwapItem} from "../../types";
import {PhoneNumberRenderer} from "../app";
import {buildSwapTarget, findAllPhoneNumbers, getXPath, reformatPhoneNumber} from "./common";
import {SwapEvent} from "../events";
import {debug} from "../debugging";

export const nonVanityPhoneNumberPattern = new RegExp(/(?:(?:\+?([1-9]|[0-9][0-9]|[0-9][0-9][0-9])\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([0-9][1-9]|[0-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g);
export const phoneNumberPattern = new RegExp(/(?:(?:\+?([1-9]\d{0,2})\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]\d{2}|[0-9]{3})\s*(?:[.-]\s*)?([A-Za-z0-9]{1,})\s*(?:[.-]\s*)?([A-Za-z0-9]{1,})\s*(?:[.-]\s*)?([A-Za-z0-9]{1,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g);

export type FormattedPhoneNumber = {
    originalFormat: string,
    raw: string
}

export const renderReplacement: PhoneNumberRenderer = (node: Node, swaps: SwapItem[]) => {
    const swapTargetList = swaps.reduce((targets: string[], swap) => [...targets, ...swap.swapTargets], [])


    const replaceTextValue = (text: string) => {
        return findAllPhoneNumbers(text).reduce((result: string, phoneNumber: FormattedPhoneNumber): string => {

            const target = buildSwapTarget(phoneNumber.raw).toUpperCase();

            const swap = swaps.filter((swap) => swap.swapTargets.includes(target)).pop();

            if (swap === undefined) {
                return result;
            }

            const replacement = reformatPhoneNumber(phoneNumber.originalFormat, swap.number)

            debug(`swapping ${phoneNumber.raw} -> ${swap.number}`);

            window.dispatchEvent(new CustomEvent<SwapEvent>('800dni.swap', {
                detail: {
                    node,
                    replacement: replacement,
                    replacementE164: swap.number,
                    original: phoneNumber.originalFormat,
                    originalE164: target,
                    selector: getXPath(node.parentElement),
                }
            }))

            return result.replace(
                phoneNumber.originalFormat,
                replacement,
            );
        }, text);
    }

    const replaceHrefAttribute = (node: Element) => {
        const href = node.getAttribute('href');

        if (href) {
            node.setAttribute(
                'href',
                replaceTextValue(decodeURI(href)),
            );
        }
    }

    if (node instanceof HTMLAnchorElement) {
        replaceHrefAttribute(node);
    } else {
        node.nodeValue = replaceTextValue(node.nodeValue);
        if (node.parentElement) {
            replaceHrefAttribute(node.parentElement);
        }
    }
}
